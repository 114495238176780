import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'

import Layout from '../components/Layout'
import {
  Section,
  InnerMedium,
} from '../components/Sections'
import SectionHeading from '../components/SectionHeading'
import SEO from '../components/seo'

const InnerStyled = styled(InnerMedium)`
  padding-top: 0;
`

const Table = styled.div`
  border-top: 1px solid silver;
  margin-bottom: 80px;
`

const Row = styled.div`
  border-bottom: 1px solid silver;
`

const HeaderCell = styled.div`
  display: table-cell;
  font-weight: 700;
  height: 100%;
  padding: 15px 20px;
  width: 200px;
  
  ${media.lessThan('medium')`
    padding: 15px 10px;
    width: 100px;
  `}
`

const BodyCell = styled.div`
  display: table-cell;
  height: 100%;
  padding: 15px 20px;
  
  ${media.lessThan('medium')`
    padding: 15px 10px;
  `}
`

const TokushouhouPage = ({ location: { pathname } }) => (
  <Layout>
    <SEO
      title="特定商取引法に基づく表示| ITエンジニア専用のオンライン英会話スクール・Moba Pro IT English（モバプロ）"
      description="特定商取引法に基づく表示についての説明。"
      pathName={pathname}
    />
    <SectionHeading withoutAnimation>
      特定商取引法に基づく表示
    </SectionHeading>
    <Section>
      <InnerStyled>
        <Table>
          <Row>
            <HeaderCell>事業者名称</HeaderCell>
            <BodyCell>
              株式会社もばらぶ
            </BodyCell>
          </Row>
          <Row>
            <HeaderCell>運営責任者</HeaderCell>
            <BodyCell>
              鹿島和郎
            </BodyCell>
          </Row>
          <Row>
            <HeaderCell>住所</HeaderCell>
            <BodyCell>
              千葉県茂原市千沢910番地
            </BodyCell>
          </Row>
          <Row>
            <HeaderCell>メールアドレス</HeaderCell>
            <BodyCell>
              info@moba-pro.com
            </BodyCell>
          </Row>
          <Row>
            <HeaderCell>販売価格</HeaderCell>
            <BodyCell>
              料金表ページと体験レッスン申し込みページにそれぞれ記載しております
            </BodyCell>
          </Row>
          <Row>
            <HeaderCell>サービスの開始時期</HeaderCell>
            <BodyCell>
              契約成立後すみやかにレッスン日程の調整、テキストの提供を行い、レッスンを開始します。
            </BodyCell>
          </Row>
          <Row>
            <HeaderCell>申込みの有効期限</HeaderCell>
            <BodyCell>
              お申込後、利用料金のお支払いが確認できない場合、お申込みをキャンセルさせていただきますのでご了承ください。
            </BodyCell>
          </Row>
          <Row>
            <HeaderCell>お支払い方法</HeaderCell>
            <BodyCell>
              クレジットカード決済での前払い一括（Stripe社の決済サービスを利用）
            </BodyCell>
          </Row>
          <Row>
            <HeaderCell>キャンセル・返金について</HeaderCell>
            <BodyCell>
              お支払い後のキャンセルは返金を承っております。
              12回コースは特定継続的役務商品となり、クーリング・オフおよび中途解約の対象となります。
              詳しくは、下記項目をご覧ください。
            </BodyCell>
          </Row>
          <Row>
            <HeaderCell>クーリング・オフ</HeaderCell>
            <BodyCell>
              利用者は、契約書面を受領した日から起算して8日間以内であれば、
              書面により本サービス利用契約及び関連商品の購入を解除することができます（以下、本条において「クーリング・オフ」といいます）。
              <br />
              クーリング・オフをした場合、違約金及び利用した本サービスの対価等の支払いは不要です。
              <br />
              当社が利用者から金銭を受領している時は、速やかに全額を返金いたします。
              <br />
              当社が利用者に不実のことを告げ、又は威迫したことによりクーリング・オフが妨害された場合、
              利用者は、改めて当社からクーリング・オフができる旨を記載した書面を受領し、
              当社より説明を受けた日から起算して８日間以内であれば、書面によりクーリング・オフをすることができます。
              <br />
              クーリング・オフは、利用者がクーリング・オフ書面を当社宛に発信したときに、その効力が生じます。
            </BodyCell>
          </Row>
          <Row>
            <HeaderCell>中途解約</HeaderCell>
            <BodyCell>
              クーリング・オフ期間の経過後に本サービスを中途解約することをご希望の場合、
              当社に解約する旨をご連絡いただき中途解約できます。
            </BodyCell>
          </Row>
          <Row>
            <HeaderCell>中途解約の違約金</HeaderCell>
            <BodyCell>
              【クーリング・オフ期間経過後、初回のレッスン開始前に解約】
              <br />
              違約金15,000円をお支払いいただきます。
              ただし、中途解約前に利用料をお支払いいただいている場合、「お支払い済みの利用料」から、違約金を控除した残額を返金する方法により、利用料と違約金の清算を致します。
              <br />
              <br />
              【初回のレッスン開始後に解約】
              <br />
              以下a,b,cの合計額をお支払いいただきます。
              ただし、中途解約前に利用料をお支払いいただいている場合、「お支払い済みの利用料」から、
              以下a,b,cの合計額を控除した残額を返金する方法により、利用料と違約金の清算を致します。
              <br />
              a. 「実施済みレッスン料」（レッスン1回あたりの利用料に、実施済みのレッスン回数を乗じた金額）
              <br />
              b. 「実施済みレッスン料」のうち未払い分
              <br />
              c. 「違約金」として、契約残額（料金総額から「実施済みレッスン料」を差し引いた額）の20%に相当する金額（ただし上限は5万円）
            </BodyCell>
          </Row>
          <Row>
            <HeaderCell>販売数量の制限など特別の販売条件</HeaderCell>
            <BodyCell>
              商品は何度でも購入できます。延長商品につきましては、進捗度を考慮して受講者と講師が相談の上内容を協議して決定します。
            </BodyCell>
          </Row>
        </Table>
      </InnerStyled>
    </Section>
  </Layout>
)

TokushouhouPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

TokushouhouPage.defaultProps = {
  location: null,
}

export default TokushouhouPage
